import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import inMemoryJwtService from '../../services/inMemoryJwtService';

import { ReactComponent as ChecklistIcon } from '../../images/ic_check_list.svg';
import { ReactComponent as DownloadIcon } from '../../images/ic_download.svg';
import { ReactComponent as OngoingIcon } from '../../images/ic_ongoing.svg';
import { ReactComponent as FailedIcon } from '../../images/ic_warning.svg';
import { ReactComponent as DetailsIcon } from '../../images/ic_details.svg';
import { ReactComponent as RefreshIcon } from '../../images/ic_refresh.svg';
import moment from 'moment';
import { useState } from 'react';
import { URL_POST_GENERATE_REPORT } from '../../consts/endpoint';

const submitRegenerateReport = async (id) => {
  await axios.post(URL_POST_GENERATE_REPORT,
    {
      "id": id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
      }
    }
  );
}

const downloadReport = async (key) => {
  const result = await axios.get(`https://6flx3eon0f.execute-api.ap-southeast-1.amazonaws.com/stg/document?key=Reports/${key}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
      }
    });
    console.log(result.data.url);
    window.open(result.data.url, "_blank");
}

const SuccessRow = ({ report }) => {
  const reportDate = moment(new Date(report.generatedAt)).format('DD/MM/YY, h:mm A');
  return (
    <div className="report-row success-report">
      <div className="report-left-section">
        <div className="noflex-mobile">
          <ChecklistIcon className="pseudo-list-icon" />
          <div className="report-title">{report.name}</div>
        </div>
        <div className="report-status">Generated {reportDate}</div>
      </div>
      <div className="report-right-section">
        <Link onClick={() => {
          downloadReport(report.url.split('/').pop())
        }} className="report-action"><DownloadIcon className="report-row-icon success-icon" />Download</Link>
      </div>
    </div>
  );
};

const OngoingRow = ({ report }) => {
  const reportDate = moment(new Date(report.generatedAt)).format('DD/MM/YY, h:mm A');

  return (
    <div className="report-row ongoing-report">
      <div className="report-left-section">
        <OngoingIcon className="pseudo-list-icon" />
        <div className="report-title">{report.name}</div>
        <div className="report-status">Generating Started {reportDate}</div>
      </div>
    </div>
  );
};

const FailedRow = ({ report, onRegenerate }) => {
  const [expanded, setExpanded] = useState(false);
  const reportDate = moment(new Date(report.generatedAt)).format('DD/MM/YY, h:mm A');
  return (
    <div className="report-row failed-report">
      <div>
        <div className="report-left-section">
          <FailedIcon className="pseudo-list-icon" />
          <div className="report-title">{report.name}</div>
          <div className="report-status">Failed to generate {reportDate}</div>
        </div>
        {expanded && (<p style={{ marginTop: 8, marginBottom: 0, color: '#0F0F0F', fontWeight: 500, fontSize: 14 }}>{report.error}</p>)}
      </div>
      <div className="report-right-section d-flex">
        <Link onClick={() => setExpanded(!expanded)} className="report-action me-4"><DetailsIcon className="report-row-icon" />{expanded ? 'Hide details' : 'See details'}</Link>
        <Link onClick={() => {
          submitRegenerateReport(report.coreId);
          onRegenerate();
        }} className="report-action"><RefreshIcon className="report-row-icon" />Regenerate</Link>
      </div>
    </div>
  );
};

const ReportRow = (props) => {
  const { reportRow } = props;
  console.log('reportRow', reportRow);
  if (reportRow) {
    const { status } = reportRow;
    switch (status) {
      case 1:
        return <SuccessRow report={reportRow} />;
      case -1:
        return <OngoingRow report={reportRow} />;
      case 0:
        return <FailedRow report={reportRow} onRegenerate={props.onRegenerate} />;
      default:
        return null;
    }
  } else {
    return <div style={{ margin: '1em 0' }}>No reports available</div>;
  }
}

export default ReportRow;