import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ReactComponent as PantherLogo } from '../images/pp-header.svg';
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { inputSelectors } from "../store/selectors/InputSelector";
import inMemoryJwtService from "../services/inMemoryJwtService";
import { URL_POST_CHECK_ITEM, URL_POST_CREATE_ITEM } from "../consts/endpoint";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    width: 180px;
    height: 180px;
    background-image: url(${props => props.loading ? 'ellips.png' : 'ellipse_green.png'});
    background-repeat: no-repeat;
    animation: ${spin} 0.6s linear infinite;
`;
const GenerateReportPage = (props) => {
    const navigate = useNavigate();

    var resourceIntervalRef = useRef(null);
    // var reportIntervalRef = useRef(null);
    const inputData = useSelector(inputSelectors.data);
    const { files, description, industry, name, questions } = inputData || {};

    let decodedToken = {};
    if (inMemoryJwtService.getToken()) {
        decodedToken = jwtDecode(inMemoryJwtService.getToken());
    }
    const { user_id, email, name: userName } = decodedToken;
    const [uploadFinish, setUploadFinish] = useState(false);

    const [fileProperties, setFileProperties] = useState([]);
    useEffect(() => {
        const checkResource = async () => {
            const response = await axios.post(URL_POST_CHECK_ITEM,
                { files: files },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
                    }
                }
            );

            if (response.data.isCompleted) {
                setFileProperties(response.data.fileProperties);
                setUploadFinish(true);
                clearInterval(resourceIntervalRef.current);
            }
        };

        resourceIntervalRef.current = setInterval(checkResource, 5000);
        return () => {
            clearInterval(resourceIntervalRef.current);
        }
    }, []);

    const title = 'Generating your report...';
    const doCreateItem = async () => {
        // setLoading(true);
        const body = {
            sid: localStorage.getItem('session_id'),
            description,
            companyName: name,
            industry,
            documents: fileProperties,
            questions: questions?.map(q => {
                return {
                    question: q.value
                }
            })
        };

        try {
            const response = await axios.post(URL_POST_CREATE_ITEM,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
                    }
                }
            );
            if (response.status === 200) {
            }
            localStorage.removeItem('session_id');
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (inputData) {
            if (uploadFinish) {
                doCreateItem();
            }
        } else {
            navigate('/');
        }
    }, [uploadFinish])

    return (
        <div className="d-flex flex-column align-items-center" style={{ padding: '1em' }}>
            <div style={{ marginTop: 124 }}>
                {/* <Loader loading={(loading || !uploadFinish) ? "true" : undefined} /> */}
                <PantherLogo style={{ width: 96, height: 96, marginTop: '-110%', padding: 0 }} />
            </div>
            <p className="py-0" style={{ fontSize: '3rem', fontWeight: 'bold', marginTop: 24 }}>{title}</p>
            {/* {!loading && success && ( */}
            <>
                <p style={{ fontSize: '1.5rem' }}>We will send a copy of your report to <b>{email}</b>.</p>
                <p style={{ fontSize: '1.5rem', marginTop: 32 }}>You can also download your report from your dashboard</p>
                {/* <Button style={{ fontSize: '1.5rem', fontWeight: 500 }} href={report} target="_blank" rel="noopener noreferrer"><DownloadIcon className="me-2" />Download my report</Button> */}
                <Button style={{ fontSize: '1.5rem', fontWeight: 500 }} href={'dashboard'} disabled={!uploadFinish}>Go to my dashboard</Button>
            </>
            {/* )} */}
            {/* {!loading && !success && uploadFinish && ( */}
            <>
                {/* <Button style={{ fontSize: '1.5rem', fontWeight: 500 }} onClick={doCreateItem}>Try Again</Button> */}
            </>
            {/* )} */}
        </div>
    )
}

export default GenerateReportPage;