import {createStore, applyMiddleware, compose} from 'redux';
import { rootReducers } from './reducers';

export default () => {
    const middlewares = [];
    const store = createStore(
        rootReducers,
        compose(applyMiddleware()),
    );

    return {store};
}