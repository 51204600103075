import { SET_USER_PROPS_DATA, GET_USER_PROPS_DATA } from "../actions/ActionTypes";

const initialState = {
    data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PROPS_DATA:
            return state;
        case SET_USER_PROPS_DATA:
            return {
                ...state, data: action.data,
            };
        default:
            return state;
    }
}