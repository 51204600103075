import { SET_USER_DATA } from "../actions/ActionTypes";
const initialState = {
    data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state, data: action.data,
            };
        default:
            return state;
    }
}