import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import Placeholder from 'react-bootstrap/Placeholder';
import axios from 'axios';
import moment from 'moment';

import inMemoryJwtService from "../services/inMemoryJwtService";

import ReportRow from './commons/ReportRow';
import SubscriptionOfferBanner from './commons/SubscriptionOfferBanner';
import { Button } from 'react-bootstrap';
import { URL_GET_REPORTS, URL_POST_GET_USER } from '../consts/endpoint';

const LoaderSection = () => {
  return (
    <div className="report-group-section">
      <div className="report-group-wrapper">
        <Placeholder animation="glow">
          <div className="report-row" style={{ padding: '1em 1.5em' }}>
            <Placeholder lg={4} style={{ borderRadius: '4px' }} /> <Placeholder lg={5} style={{ borderRadius: '4px' }} /> <Placeholder lg={2} style={{ borderRadius: '4px' }} />
          </div>
        </Placeholder>
      </div>
    </div>
  )
}

const getEndBillingDate = dateStr => {
  console.log('dateStr', dateStr);
  let date = moment(dateStr, 'YYYYMMDD');
  date = date.add(1, 'months');
  return date.format('DD/MM/YYYY');
};

const DashboardPage = (props) => {
  const token = inMemoryJwtService.getToken();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [reportsRetrieved, setReportsRetrieved] = useState(false);
  const [user, setUser] = useState(null);

  const fetchUser = async (token) => {
    try {
      const response = await axios.get(URL_POST_GET_USER,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setUser(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const fetchReports = async () => {
    try {
      const response = await axios.get(`${URL_GET_REPORTS}?limit=1`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
          }
        }
      );
      setReports(
        response?.data?.data?.map(item => {
          const report = item.report;
          return {
            id: report.id,
            name: report.name,
            status: item.status,
            details: report.message,
            url: report.url,
            generatedAt: report.generatedAt
          }
        })
      )
    } catch (err) {
      console.error(err);
    }
    setReportsRetrieved(true);
  }

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    fetchUser(token);
  }, [token])

  const countedToken = user?.plan?.token - user?.qToken;

  return (
    <div className="sidebar-adjusted-container">
      <div className="section-container">
        <h1>{`Welcome, ${user?.name?.split(' ')[0]}.`}</h1>
      </div>
      <div className="empty-divider"></div>
      {/* <div>
        <SubscriptionOfferBanner />
        <div className="empty-divider"></div>
      </div> */}
      <div className="section-container banner-section">
        {/* <SubscriptionOfferBanner /> */}

        {/* <div className='d-flex align-items-center' style={{
          width: 'calc(100% - 56px)',
          margin: 'auto',
          marginTop: 41,
          borderRadius: 16,
          padding: 24,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
        }}> */}
        <div className='align-items-center noflex-mobile dash-card-container' style={{
          margin: 'auto',
          borderRadius: 16,
          padding: 24,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
        }}>
          <div className='flex-fill'>
            <p style={{ color: '#00171F', fontSize: 24 }}>You have {user?.qToken ? <strong>{user?.qToken} questions</strong> : <div style={{ width: 100, height: 24, display: 'inline-flex' }}><Placeholder animation="glow" style={{ width: 100, height: 24 }}><Placeholder style={{ borderRadius: '4px', width: 100, height: 24 }} /></Placeholder></div>} left for this month</p>
            <div className="progress">
              <div className={`progress-bar bg-${(user?.plan?.token - user?.qToken) / user?.plan?.token === 1 ? 'danger' : 'primary'}`} role="progressbar" style={{ width: `${((user?.plan?.token - user?.qToken) / user?.plan?.token) * 100}%` }}></div>
            </div>
            <p style={{ color: '#00171F', fontSize: 14, fontWeight: 600, marginTop: 10 }}>{ countedToken }/{user?.plan?.token} questions used</p>
            <p style={{ color: '#00171F', marginTop: 16 }}>Your available questions will refresh to {user?.plan?.token} questions on <strong>{getEndBillingDate(user?.planAt)}</strong></p>
          </div>
          <Button className="dashboard-row-btn" onClick={() => {
            if ((user?.plan?.token - user?.qToken) / user?.plan?.token === 1)
              navigate('/billing')
            else navigate('/core')
          }}>{(user?.plan?.token - user?.qToken) / user?.plan?.token === 1 ? 'Upgrade for more' : 'Ask new questions'}</Button>
        </div>
      </div>
      <div className="empty-divider"></div>
      <div className="section-container reports-section">
        <div className="section-header-bar">
          <h2 className="section-title">Your Recent Reports</h2>
          <Link className="pp-nav-link-style1" to="/reports">See all</Link>
        </div>
        <hr />
        <div className="section-content">
          <div className="report-list">
            {
              reportsRetrieved ?
                <ReportRow reportRow={reports[0]} />
                :
                <LoaderSection />
              // reports.map((row, key) => (<ReportRow key={key} reportRow={row} />))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;