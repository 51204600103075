import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as AddIcon } from '../images/ic_add_24.svg';
import QuestionHeader from "./commons/QuestionHeader";
import QuestionGroup from "./commons/QuestionGroup";
import CustomQuestion from "./commons/CustomQuestion";
import { useDispatch, useSelector } from "react-redux";
import { inputSelectors } from "../store/selectors/InputSelector";
import { setCompanyData } from "../store/actions/InputAction";
import InputQuestion from "./commons/InputQuestion";
import inMemoryJwtService from "../services/inMemoryJwtService";
import { URL_POST_GET_USER } from "../consts/endpoint";
const predefinedQuestions = [{
    id: 0,
    value: "How can I reduce my operational costs without compromising quality?",
    category: 'finance'
}, {
    id: 1,
    value: "What are some ways to negotiate better terms with suppliers and vendors to reduce my expenses?",
    category: 'finance'
}, {
    id: 2,
    value: "How can I identify and eliminate unnecessary expenses without affecting my business operations?",
    category: 'finance'
}, {
    id: 3,
    value: "What are some best practices for managing cash flow and improving my company's financial health?",
    category: 'finance'
}, {
    id: 4,
    value: "What are the most effective strategies to identify and target new customer segments to increase my revenue?",
    category: 'bd'
}, {
    id: 5,
    value: "How can I optimize my pricing strategy to maximize profitability without losing customers?",
    category: 'bd'
}, {
    id: 6,
    value: "What are some innovative marketing techniques I can implement to increase brand awareness and attract more customers on a limited budget?",
    category: 'bd'
}, {
    id: 7,
    value: "How can I develop a long-term, sustainable growth plan that balances revenue growth with cost management?",
    category: 'bd'
}, {
    id: 8,
    value: "How can I leverage technology to automate tasks, increase efficiency, and reduce labor costs?",
    category: 'ops'
}, {
    id: 9,
    value: "What are some strategies to improve employee productivity and motivation while keeping labor costs under control?",
    category: 'hr'
}]

const SelectQuestions = ({ onNext, userPropsData, ...props }) => {
    const dispatch = useDispatch();
    const inputData = useSelector(inputSelectors.data);
    const [open, setOpen] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [customQuestion, setCustomQuestion] = useState('');
    const [questionsLimit, setQuestionsLimit] = useState(2);

    const fetchUser = async (token) => {
        try {
            const response = await axios.get(URL_POST_GET_USER,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setQuestionsLimit(response.data.qToken)
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchUser(inMemoryJwtService.getToken());
    }, [questionsLimit]);

    useEffect(() => {
        if (inputData.questions) setQuestions(inputData.questions);
    }, []);

    useEffect(() => {
        dispatch(setCompanyData({ ...inputData, questions }));
    }, [questions]);

    const handleCheckChanged = (question, checked) => {
        if (checked === true) {
            setQuestions([...questions, question]);
        } else if (checked === false) {
            setQuestions(questions.filter(q => q.id !== question.id));
        }
    }

    return (
        <div className="d-flex flex-column container-sm pb-5 px-5 align-items-start" style={{ marginBottom: 82 }} {...props}>
            <p className="align-self-center" style={{ fontSize: '3rem', fontWeight: 'bold', textAlign: userPropsData?.isFirst ? 'center' : 'left' }}>Tell us what you want to improve in your business</p>
            <p style={{ fontSize: '1.5rem', textAlign: 'left', marginBottom: 34 }}>
                Choose <b>{questionsLimit} questions</b> for Pulse to answer for your company.
                Write your own or select from our curated list below.
            </p>
            <Link to="https://pantherpulse.ai" target="blank" style={{ textDecoration: 'none', color: '#924DF6', fontSize: '1.5rem', marginBottom: 40 }}><p>Can I ask more questions?</p></Link>
            {(questions.length < questionsLimit || questions.filter(q => q.category === 'custom').length > 0) && (<QuestionHeader title="Written by you" />)}
            {
                questions.filter(q => q.category === 'custom').map((q, i) => (
                    <CustomQuestion
                        key={i}
                        question={q.value}
                        style={{ marginTop: 18 }}
                        onDelete={e => {
                            setQuestions(questions.filter(el => el !== q));
                        }}
                        onEdit={() => {
                            setCustomQuestion(q.value);
                            setOpen(true);
                        }}
                        onValueChange={value => {
                            const newQuestions = questions.map((q, key) => {
                                if (key === i) return { ...q, value: value };
                                else return q;
                            })
                            setQuestions(newQuestions);
                        }} />
                ))
            }
            {questions.length < questionsLimit && (<div className="w-100 p-3" style={{
                backgroundColor: '#E7E2EF',
                marginTop: 18,
                borderRadius: 4,
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23924DF6FF' stroke-width='1' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
            }}>
                {!open && (<div className="d-flex align-items-center justify-content-center">
                    <p className="my-0" style={{
                        color: '#4C4C4C',
                        fontSize: '1.25rem',
                    }}>Have a burning question for Pulse to answer? </p>
                    <Link className="d-flex align-items-center" style={{ marginLeft: 16, textDecoration: 'none', color: '#924DF6', fontSize: '1.25rem' }} onClick={e => {
                        if (questions.length < questionsLimit) setOpen(true);
                    }}>
                        <p className="my-0 me-1">Add your own</p>
                        <AddIcon />
                    </Link>
                </div>)}
                {open && (<InputQuestion onSave={q => {
                    setQuestions([...questions, { value: q, category: 'custom' }]);
                    setOpen(false);
                }} onCancel={() => {
                    setOpen(false);
                }} />)}
            </div>)}
            <div className="flex-grow-1">
                <QuestionGroup disabled={questions.length >= questionsLimit} selectedQuestions={questions} group="Finance" questions={predefinedQuestions.filter(q => q.category === 'finance')} onCheckChange={handleCheckChanged} />
                <QuestionGroup disabled={questions.length >= questionsLimit} selectedQuestions={questions} group="Business Development (BD)" questions={predefinedQuestions.filter(q => q.category === 'bd')} onCheckChange={handleCheckChanged} />
                <QuestionGroup disabled={questions.length >= questionsLimit} selectedQuestions={questions} group="Operations" questions={predefinedQuestions.filter(q => q.category === 'ops')} onCheckChange={handleCheckChanged} />
                <QuestionGroup disabled={questions.length >= questionsLimit} selectedQuestions={questions} group="Human Resources (HR)" questions={predefinedQuestions.filter(q => q.category === 'hr')} onCheckChange={handleCheckChanged} />
            </div>
            {questions.length > 0 && (<div className="d-flex align-items-center px-5" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: 82, backgroundColor: '#E7E2EF', zIndex: 999 }}>
                <p className="my-0 flex-grow-1 phone-font-default" style={{ textAlign: 'left', fontSize: '1.5rem' }}>You’ve selected <b>{questions.length} of {questionsLimit}</b> questions</p>
                <Button className="phone-font-default" style={{ fontWeight: 500, fontSize: '1.5rem' }} onClick={() => {
                    onNext();
                }}>Continue</Button>
            </div>)}
        </div>
    )
}
export default SelectQuestions;