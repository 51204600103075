import { GET_COMPANY_DATA, SET_COMPANY_DATA } from "./ActionTypes";

export function setCompanyData(data) {
    return {
        type: SET_COMPANY_DATA,
        data,
    };
}

export function getCompanyData(){
    return {
        type: GET_COMPANY_DATA,
    };
}