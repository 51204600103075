export const URL_POST_GET_USER = 'https://9lww3x3ii4.execute-api.ap-southeast-1.amazonaws.com/stg/user';
export const URL_GET_PRICING = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/pricing';
export const URL_POST_CANCEL_SUBS = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/cancel-subscription';
export const URL_POST_PORTAL_SESS = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/portal-session';
export const URL_GET_REPORTS = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/reports';
export const URL_POST_CHECK_ITEM = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/item/check';
export const URL_POST_CREATE_ITEM = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/item/create';
export const URL_UPLOAD_DOC = 'https://6flx3eon0f.execute-api.ap-southeast-1.amazonaws.com/stg/document/upload';
export const URL_POST_CHECKOUT_SESS = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/checkout-session';
export const URL_POST_GENERATE_REPORT = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/item/generate-report';
export const URL_GET_INVOICE = 'https://rbqwofafx9.execute-api.ap-southeast-1.amazonaws.com/stg/get-invoice';