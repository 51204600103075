import { useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";

const TabLayout = ({ items, initialSelected, onTabSelected, ...props }) => {
    const [selected, setSelected] = useState(initialSelected || 0);
    useEffect(() => {
        onTabSelected(selected);
    }, [selected]);
    return (
        <div className="d-flex flex-fill" {...props}>
            {
                items.map((item, key) => {
                    return (
                        <ButtonTab key={key} text={item} onItemClick={e => {
                            setSelected(key)
                        }} selected={selected == key} />
                    )
                })
            }
        </div>
    )
}

export default TabLayout;