import Form from 'react-bootstrap/Form';
import ReportRow from './commons/ReportRow';
import axios from 'axios';
import Placeholder from 'react-bootstrap/Placeholder';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import inMemoryJwtService from '../services/inMemoryJwtService';
import { URL_GET_REPORTS } from '../consts/endpoint';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const LoaderSection = () => {
  return (
    <div className="report-group-section">
      <div className="report-group-title" style={{ marginBottom: '1em' }}>
        <Placeholder animation="glow" lg={2} style={{ borderRadius: '4px' }}>
          <Placeholder xs={2} style={{ borderRadius: '4px' }} />
        </Placeholder>
      </div>
      <div className="report-group-wrapper">
        <Placeholder animation="glow">
          <div className="report-row" style={{ padding: '1em 1.5em' }}>
            <Placeholder lg={4} style={{ borderRadius: '4px' }} /> <Placeholder lg={5} style={{ borderRadius: '4px' }} /> <Placeholder lg={2} style={{ borderRadius: '4px' }} />
          </div>
        </Placeholder>
        <Placeholder animation="glow">
          <div className="report-row" style={{ padding: '1em 1.5em' }}>
            <Placeholder lg={4} style={{ borderRadius: '4px' }} /> <Placeholder lg={5} style={{ borderRadius: '4px' }} /> <Placeholder lg={2} style={{ borderRadius: '4px' }} />
          </div>
        </Placeholder>
        <Placeholder animation="glow">
          <div className="report-row" style={{ padding: '1em 1.5em' }}>
            <Placeholder lg={4} style={{ borderRadius: '4px' }} /> <Placeholder lg={5} style={{ borderRadius: '4px' }} /> <Placeholder lg={2} style={{ borderRadius: '4px' }} />
          </div>
        </Placeholder>
      </div>
    </div>
  )
};

const filterQueryArr = [
  { val: 'all', query: '?limit=10' },
  { val: '1', query: '?status=1&limit=10' },
  { val: '0', query: '?status=0&limit=10' },
  { val: '-1', query: '?status=-1&limit=10' }
];

const ReportsPage = () => {

  const defaultFilter = {
    year: 'all',
    status: 'all'
  };

  const [filterVal, setFilterVal] = useState(defaultFilter);
  const [reports, setReports] = useState([]);
  const [reportsRetrieved, setReportsRetrieved] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [lastKey, setLastKey] = useState('');
  const [hasMore, setHasMore] = useState(true);
  // const loaderRef = useRef(null);

  const ReportsPageLayoutSetup = ({ reports, retrieved }) => {
    let reportKey = 0;
    let reportsLayout = [];
    let prevMonth;
    let prevYear;
    console.log('reports', reports);
    if (reports.length && retrieved) {
      for (const report of reports) {
        const reportDate = new Date(report.generatedAt);
        const currentMonth = reportDate.getMonth();
        const currentYear = reportDate.getFullYear();
        let reportLayout = [];
        if (currentMonth !== prevMonth || currentYear !== prevYear) {
          reportLayout.push(<div className="report-group-section" key={reportKey}><div className="report-group-title">{months[currentMonth]} {currentYear}</div><div className="empty-divider-small"></div></div>);
          reportKey += 1;
        }
        reportLayout.push(<ReportRow reportRow={report} key={reportKey} onRegenerate={() => {
          setReports(reports.map(item => {
            console.log('itemmmm', item);
            if (item.coreId === report.coreId) return {
              ...item,
              generatedAt: new Date().getTime(),
              status: -1
            };
            return item;
          }));
        }} />);
        reportsLayout.push(reportLayout);
        prevMonth = currentMonth;
        prevYear = currentYear;
        reportKey += 1;
      }
      return reportsLayout;
    } else {
      return (<div>No reports available</div>);
    }
  };

  const fetchReports = async (reportQuery, reload = true) => {
    try {
      const response = await axios.get(`${URL_GET_REPORTS}${reportQuery}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
          }
        }
      );
      console.log('response', response);
      setLastKey(response?.data?.lastKey);
      const updatedReports = response?.data?.data?.map(item => {
        const report = item.report;
        return {
          coreId: item.id,
          id: report.id,
          name: report.name,
          status: item.status,
          details: report.message,
          url: report.url,
          error: report.message,
          generatedAt: report.generatedAt
        }
      });
      console.log('updatedReports', updatedReports);
      setHasMore(updatedReports.length >= 3);
      if (reload) {
        setReports(updatedReports);
        setReportsRetrieved(true);
      } else {
        setReports([
          ...reports,
          ...updatedReports
        ]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchReports('?limit=10');
    setLoadMore(false);
  }, []);

  const fetchMoreData = () => {
    console.log('lastKey', lastKey);
    setLoadMore(true);
    const selectedQuery = filterQueryArr.find(query => query.val === filterVal.status);
    let finalQuery = selectedQuery.query;
    if (lastKey) {
      finalQuery += `&lastKeyId=${lastKey.id}&lastKeyCreatedBy=${lastKey.createdBy}&lastKeyCreatedAt=${lastKey.createdAt}`;
    }
    fetchReports(finalQuery, false);
  }

  // Experimental Setup
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting) {
  //       alert('fetch report triggered');
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // });

  const handleFilterType = async (e, selectedType) => {
    setLoadMore(false);
    setFilterVal({
      ...filterVal,
      [selectedType]: e.target.value
    });
    const selectedQuery = filterQueryArr.find(query => query.val === e.target.value);
    try {
      console.log('selectedQuery.query', selectedQuery.query);
      fetchReports(selectedQuery.query);
      setLoadMore(true);
    } catch (err) {
      console.log('error', err);
    }
  };

  console.log('reports', reports);

  return (
    <div className="sidebar-adjusted-container">
      <div className="section-container">
        <h2 className="section-title">Reports</h2>
      </div>
      <div className="empty-divider"></div>
      <div className="section-container">
        <div className="fullwidth-card">
          <div className="filter-nav-section">
            {/* <Form.Select aria-label="Select year" className="report-select-filter" onChange={(e) => handleFilterType(e, 'year')} value={ filterVal.year } >
              <option>Year</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </Form.Select> */}
            <Form.Select aria-label="Select Status" className="report-select-filter" onChange={(e) => handleFilterType(e, 'status')} value={filterVal.status} >
              <option value="all">All Statuses</option>
              <option value="1">Generated</option>
              <option value="-1">Generating</option>
              <option value="0">Failed</option>
            </Form.Select>
          </div>
          <hr />
          <InfiniteScroll
            dataLength={reports.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoaderSection />}
          >
            <div className="report-group-wrapper">
              {
                reportsRetrieved &&
                <ReportsPageLayoutSetup reports={reports} retrieved={reportsRetrieved} />
              }
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;