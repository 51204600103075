import './App.scss';
import { Link, Route, Routes, MemoryRouter } from 'react-router-dom';
import axios from 'axios';
import Header from './components/commons/Header';
import Welcome from './components/Welcome';
import MainPage from './components/MainPage';
import LandingPage from './components/LandingPage';
import GenerateReportPage from './components/GenerateReportPage';
import DashboardPage from './components/DashboardPage';
import ReportsPage from './components/ReportsPage';
import CheckoutPage from './components/CheckoutPage';

import inMemoryJwtService from './services/inMemoryJwtService';
import { useSelector } from 'react-redux';
import { authSelector } from './store/selectors/AuthSelector';
import { userPropsSelectors } from './store/selectors/UserPropsSelector';
import { auth } from './utils/firbase.utils';
import { useDispatch } from 'react-redux';
import { setUserData } from './store/actions/AuthAction';
import { setUserPropsData } from './store/actions/UserPropsAction';
import BillingPage from './components/BillingPage';
import { URL_POST_GET_USER } from './consts/endpoint';

function App() {
  const userData = useSelector(authSelector.data);
  const userPropsData = useSelector(userPropsSelectors.data);
  const dispatch = useDispatch();
  auth.onAuthStateChanged(async user => {
    if (user) {
      try {
        const response = await axios.get(URL_POST_GET_USER,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.accessToken}`
            }
          }
        );
        inMemoryJwtService.setToken(user.accessToken);
        dispatch(setUserData(user.accessToken));
        if (!userPropsData) {
          dispatch(setUserPropsData(response.data));
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      inMemoryJwtService.deleteToken();
      dispatch(setUserData(null));
    }
  });
  return (
    <div className="App">
      {inMemoryJwtService.getToken() && (<Header />)}
      {inMemoryJwtService.getToken() && (<div style={{ height: 64 }} />)}
      <Routes>
        <Route path="/"
          element={
            <>
              {inMemoryJwtService.getToken() && (<Welcome />)}
              {!inMemoryJwtService.getToken() && (<LandingPage />)}
            </>
          } />
        {/* <Route path="/welcome"
          element={<Welcome />} /> */}
        {inMemoryJwtService.getToken() && (<>
          <Route path="/core"
            element={<MainPage />} />
          <Route path="/result"
            element={<GenerateReportPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/billing" element={<BillingPage />} />
          <Route path="/reports" element={< ReportsPage />} />
          <Route path="/checkout" element={< CheckoutPage />} />
        </>)}
      </Routes>
    </div>
  );
}

export default App;

/*
the routes should be :
1. /          => signup or login
2. /welcome   => Get Started
3. /core      => Fill in company desc, ask questions, upload
4. /result

header should be appear when user logged in
**/
