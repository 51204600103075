import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardMenuIcon } from '../../images/ic_dashboard.svg';
import { ReactComponent as ReportsMenuIcon } from '../../images/ic_insert_drive_file.svg';
import { ReactComponent as SettingsMenuIcon } from '../../images/ic_settings.svg';
import { ReactComponent as BillingMenuIcon } from '../../images/ic_credit_card.svg';

const DrawerNavigation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const navigateDrawer = () => {
    props.hideMenuActive();
  }

  return (
    <div className={`drawer-menu${ props.menuActive ? ' active' : '' }`}>
      <div className="drawer-menu-item drawer-menu-top">
        <div className="item">
          <Link onClick={navigateDrawer} to={'/dashboard'} className={`${ pathname === '/dashboard' ? 'visited' : '' }`}><DashboardMenuIcon className="nav-menu-icon" />Dashboard</Link>
        </div>
        <div className="item">
          <Link onClick={navigateDrawer} to='/reports' className={`${ pathname === '/reports' ? 'visited' : '' }`}><ReportsMenuIcon className="nav-menu-icon" />Reports</Link>
        </div>
      </div>
      <div className="drawer-menu-bottom">
        {/* <div className="item">
          <Link to="/settings" className={`${ pathname === '/settings' ? 'visited' : '' }`}><SettingsMenuIcon className="nav-menu-icon" />Settings</Link>
        </div> */}
        <div className="item">
          <Link onClick={navigateDrawer} to="/billing" className={`${ pathname === '/billing' ? 'visited' : '' }`}><BillingMenuIcon className="filled nav-menu-icon" />Billing</Link>
        </div>
        <div className="item signed-drawer">
          &#169; PantherPulse 2024
        </div>
      </div>
    </div>
  )
}

export default DrawerNavigation;