import { Button, Row, Col, Container, Table } from 'react-bootstrap';
import { ReactComponent as IconCheck } from '../images/ic_check_list.svg';
import { ReactComponent as IconLink } from '../images/ic_stripe_link.svg';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TabLayout from './commons/TabLayout';
import PlanCard from './commons/PlanCard';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CancelPlanModal from './CancelPlanModal';
import BenefitItem from './commons/BenefitItem';
import inMemoryJwtService from '../services/inMemoryJwtService';
import { useSelector } from "react-redux";
import { userPropsSelectors } from "../store/selectors/UserPropsSelector";
import { URL_GET_PRICING, URL_POST_GET_USER, URL_POST_PORTAL_SESS, URL_GET_INVOICE } from '../consts/endpoint';
import SubscriptionOfferBanner from './commons/SubscriptionOfferBanner';

const dummyInvoices = [{
  date: '25 June 2024',
  type: 'Standard - Monthly',
  amount: '$39.00',
  status: 'PAID'
}, {
  date: '25 May 2024',
  type: 'Premium - Annual',
  amount: '$590.00',
  status: 'PAID'
}];

const arrPlanForOffer = ['2', '4'];

const BillingPage = (props) => {
  // change later on stripe integration

  const [selectedTab, setSelectedTab] = useState(0);
  const [userId, setUserId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPlanId, setCurrentPlanId] = useState('1');
  const [isPlanCancelled, setPlanCancelled] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const userPropsData = useSelector(userPropsSelectors.data);
  console.log('userPropsData', userPropsData);

  const fetchPlans = async () => {
    const response = await axios.get(URL_GET_PRICING);
    setPlans(response.data.sort((a, b) => a.id.localeCompare(b.id)));
    setCurrentPlan(response.data.find(item => item.id === currentPlanId));
  }

  const fetchUser = async (token) => {
    try {
      const response = await axios.get(URL_POST_GET_USER,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setCurrentPlanId(response.data.plan.id);
      setUserId(response.data.id);
      setPlanCancelled(response.data.isCancelled);
      console.log(response.data.plan.id);
    } catch (err) {
      console.error(err);
    }
  }

  const fetchInvoice = async (token) => {
    const response = await axios.get(URL_GET_INVOICE,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    );
    setInvoices(response?.data?.invoices?.data);
  }

  const combineAPICall = async () => {
    await fetchUser(inMemoryJwtService.getToken());
    await fetchPlans();
    await fetchInvoice(inMemoryJwtService.getToken());
  }

  const domainSource = window.location.origin;

  console.log('plans', plans);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    combineAPICall();
  }, [currentPlanId]);
  return (
    <div className="sidebar-adjusted-container">
      <div className="section-container">
        <p style={{ fontSize: 32 }}>Billing</p>
        <div style={{
          // width: 'calc(100% - 200px)',
          margin: 'auto',
          borderRadius: 16,
          padding: 24,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
        }}>
          <p style={{ fontSize: 22, fontWeight: 'bold', color: '#00171F', }}>Current Plan</p>
          {
            userPropsData?.plan?.id &&
            <div className="noflex-mobile align-items-end">
              <p className="flex-grow-1" style={{ fontSize: 32, color: '#00171F' }}>{userPropsData?.plan?.name}</p>
              <p style={{ fontSize: 16, color: '#00171F' }}>This plan is <b>billed {userPropsData?.plan?.type === 1 ? 'annually' : 'monthly'} at USD ${userPropsData?.plan?.price}/{userPropsData?.plan?.type === 1 ? 'year' : 'month'}</b></p>
            </div>
          }
          <div style={{
            width: '100%',
            height: 1,
            backgroundColor: '#D9D9D9'
          }}></div>
          <p style={{ fontSize: 16, color: '#00171F', marginTop: 16 }}>Included in this plan:</p>

          {
            userPropsData?.plan?.benefits.map((text, key) => {
              return (<BenefitItem key={key} text={text} textColor='#00171F' />)
            })
          }
        </div>
        {
          arrPlanForOffer.indexOf(currentPlanId) > -1 &&
          <div>
            <div className="empty-divider"></div>
            <SubscriptionOfferBanner userPropsData={userPropsData} returnLink={`${domainSource}/billing`} />
          </div>
        }
        {
          userPropsData?.subscribedPlan &&
          <div>
            <div className="empty-divider"></div>
            <div style={{
              // width: 'calc(100% - 200px)',
              margin: 'auto',
              borderRadius: 16,
              padding: 24,
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
            }}>
              <p style={{ fontSize: 22, fontWeight: 'bold', color: '#00171F', }}>Payment Details</p>
              <form action={URL_POST_PORTAL_SESS} method="POST">
                <input
                  type="hidden"
                  name="customer_id"
                  value={ userPropsData?.stripeCustomer }
                  />
                <input
                  type="hidden"
                  name="lookup_key"
                  value={ `${domainSource}/billing` }
                  />
                <Button variant="outline-primary" id="checkout-and-portal-button" type="submit">
                  Update my payment details
                </Button>
              </form>
            </div>
          </div>
        }
        {
          currentPlanId === '1' &&
            <div style={{
              // width: 'calc(100% - 200px)',
              margin: 'auto',
              marginTop: 41,
              borderRadius: 16,
              padding: 24,
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
            }}>
              <p style={{ fontSize: 22, margin: 0, fontWeight: 'bold', color: '#00171F', marginBottom: 32 }}>Upgrade your plan</p>
              <div className='d-flex'>
                <TabLayout onTabSelected={selected => setSelectedTab(selected)} items={["Monthly", "Yearly"]} style={{ marginBottom: 24 }} />
                <div><p style={{
                  fontSize: 16,
                  color: 'white',
                  margin: 0,
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: '#00171F',
                  borderRadius: 8
                }}>Save up to <strong>15%</strong> on yearly plans</p>
                </div>
              </div>
              <Container>
                <Row>
                  {plans.filter(plan => (plan.type === selectedTab) || plan.id === '1').map((plan, key) => {
                    return (
                      <Col key={key} >
                        <PlanCard planItem={plan} planType={selectedTab} currentPlan={currentPlanId} minHeight={384} userId={userId} />
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </div>
        }
        <div>
          <div className="empty-divider"></div>
          <div style={{
            // width: 'calc(100% - 200px)',
            margin: 'auto',
            borderRadius: 16,
            padding: 24,
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
          }}>
            <p style={{ fontSize: 22, fontWeight: 'bold', color: '#00171F', }}>Invoices</p>
            <Table responsive className="table-default">
              <thead>
                <tr>
                  <th className="tableheader-default">Date</th>
                  <th className="tableheader-default">Type</th>
                  <th className="tableheader-default">Amount</th>
                  <th className="tableheader-default">Status</th>
                  <th className="tableheader-default">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  invoices && invoices.length > 0 ?
                  invoices.map((invoice, i) => {
                    const invoicePlan = plans.find(plan => plan.prodId === invoice.lines.data[0]?.plan?.product)
                    return (
                      <tr key={i}>
                        <td>{ moment(new Date(invoice.created*1000)).format('DD MMMM YY') }</td>
                        <td>{ invoicePlan.name } { invoicePlan.type === 1 ? 'Annual' : 'Monthly' }</td>
                        <td>{ USDollar.format(parseFloat(invoice.amount_due/100).toFixed(2)) }</td>
                        <td>{ invoice.status.toUpperCase() }</td>
                        <td><Link to={invoice.hosted_invoice_url} target="_blank" style={{ color: '#924DF6', textDecoration: 'none' }}>View Invoice</Link></td>
                      </tr>                      
                    );
                  })
                  :
                  'No Data'
                }
              </tbody>
            </Table>
          </div>
        </div>
        {currentPlanId !== '1' && (<div style={{
          // width: 'calc(100% - 200px)',
          margin: 'auto',
          marginTop: 41,
          borderRadius: 16,
          padding: 24,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
        }}>
          <p style={{ fontSize: 22, fontWeight: 'bold', color: '#00171F', }}>Cancellation</p>
          <div className="d-flex align-items-center">
            <p style={{ padding: 0, marginBottom: 0, marginLeft: 4, flexGrow: 1, fontSize: 16, color: '#00171F' }}>{userPropsData.isCancelled ? 'This plan already cancelled' : 'Cancel Standard Plan'}</p>

            {!userPropsData.isCancelled &&
              <Button variant='danger' onClick={() => setModalShow(true)} disabled={userPropsData.isCancelled}>Cancel</Button>
            }
          </div>
        </div>)}
        <CancelPlanModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </div>
  );
}

export default BillingPage;