import { Button, Placeholder } from 'react-bootstrap';

const PlanCardLoading = () => {
    return (
        <div className='d-flex flex-column' style={{ border: '1px solid #D9D9D9', borderRadius: 8, padding: 16 }}>
            <Placeholder animation='glow' xs={12}>
                <Placeholder xs={6} style={{ borderRadius: '8px' }} />
            </Placeholder>
            <div className='d-flex align-items-end mb-4 mt-4'>
                <Placeholder animation='glow' xs={6}>
                    <Placeholder xs={12} style={{ borderRadius: '8px' }} />
                </Placeholder>
            </div>
            <div className='d-flex align-items-center'>
                <div style={{ width: 16, height: 16, backgroundColor: 'grey', marginRight: 8, borderRadius: '8px' }} />
                <Placeholder animation='glow' xs={8}>
                    <Placeholder xs={12} style={{ borderRadius: '8px' }} />
                </Placeholder>
            </div>
            <div className='d-flex align-items-center'>
                <div style={{ width: 16, height: 16, backgroundColor: 'grey', marginRight: 8,borderRadius: '8px' }} />
                <Placeholder animation='glow' xs={10}>
                    <Placeholder xs={12} style={{ borderRadius: '8px' }} />
                </Placeholder>
            </div>
            <div className='d-flex align-items-center'>
                <div style={{ width: 16, height: 16, backgroundColor: 'grey', marginRight: 8, borderRadius: '8px' }} />
                <Placeholder animation='glow' xs={4}>
                    <Placeholder xs={12} style={{ borderRadius: '8px' }} />
                </Placeholder>
            </div>
            <Button className='mt-5' style={{ width: '100%', height: 40, borderRadius: '8px' }} />
        </div>
    )
}

export default PlanCardLoading;