import React from "react";
import { ReactComponent as CheckIcon} from '../../images/ic_checkbox_selected.svg';
import { ReactComponent as UncheckIcon} from '../../images/ic_checkbox.svg';

const Checkbox = ({ checked, onCheckedChange }) => {
    return (
        <div onClick={e => {
            if (onCheckedChange) onCheckedChange(checked);
        }}>
            {!checked && <UncheckIcon />}
            {checked && <CheckIcon />}
        </div>
    )
}

export default Checkbox;