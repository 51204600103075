import { combineReducers } from "redux";
import InputReducer from "./InputReducer";
import AuthReducer from "./AuthReducer";
import UserPropsReducer from "./UserPropsReducer";

const allReducers = combineReducers({
    input: InputReducer,
    user: AuthReducer,
    userProps: UserPropsReducer
});

export const rootReducers = allReducers;