import React, { useEffect, useState } from "react";
import ItemStepper from "./ItemStepper";

const Stepper = ({select, onSelected, ...props}) => {
    const [selected, setSelected] = useState(select ? select : 1);
    const handleItemClick = num => {
        // setSelected(num);
        // onSelected(num);
    };
    useEffect(() => {
        setSelected(select ? select : 1);
    }, [select]);
    const steps = [1, 2, 3];
    var stepper = [];
    steps.map((el, i) => {
        stepper.push(<ItemStepper key={i} num={el} selected={el <= selected} onClick={handleItemClick} />);
        if (i !== steps.length - 1) stepper.push(<div key={(i * -1)-1} className="flex-fill mx-4" style={{ backgroundColor: el < selected ? '#924DF6' : '#D9D9D9', height: 1 }} />);
    })
    return (
        <div className="d-flex align-items-center stepper-commons" {...props}>
            {
                stepper.map(el => {
                    return el;
                })
            }
        </div>
    );
}

export default Stepper;