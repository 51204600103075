import { jwtDecode } from "jwt-decode";
import { auth } from "../utils/firbase.utils";

const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let refreshTimeoutId;

    const refreshToken = async expiration => {
        const user = auth.currentUser;
        if (user) {
            const delay = new Date(expiration*1000).getTime() - new Date().getTime();
            const timeoutTrigger = delay - 5000;
            refreshTimeoutId = window.setTimeout(async () => {
                try {
                    const token = await user.getIdToken();
                    setToken(token);
                } catch (err){
                    console.error(err);
                }
            }, timeoutTrigger);
        }
    }

    const getToken = () => inMemoryJWT;

    const setToken = (token) => {
        inMemoryJWT = token;
        refreshToken(jwtDecode(token).exp);
        return true;
    };

    const deleteToken = () => {
        inMemoryJWT = null;
        return true;
    };

    return {
        getToken,
        setToken,
        deleteToken,
        refreshToken
    };
};

export default inMemoryJWTManager();