import Reac, { useState } from "react";
import { Form } from "react-bootstrap";

const CountableTextArea = (props) => {
    var { placeholder, maxLength, onChange, height, value } = props;
    const [count, setCount] = useState(value.length);
    if (!maxLength) maxLength = '2500';
    if (!height) height = 164;
    return (
        <div style={{ position: 'relative' }}>
            <Form.Control
                style={{
                    resize: 'none',
                    height: height,
                    overflow: 'auto',
                    paddingBottom: 36
                }}
                as="textarea"
                maxLength={maxLength}
                placeholder={placeholder}
                value={value}
                onChange={e => {
                    setCount(e.target.value.length);
                    if (onChange) onChange(e);
                }} />
            <p style={{
                marginBottom: 0,
                position: 'absolute',
                paddingTop: 8,
                paddingBottom: 8,
                bottom: 1,
                left: 4,
                right: 32,
                textAlign: 'right',
                fontSize: '0.9rem',
                color: '#49454F',
                backgroundColor: 'white'
            }}>
                {`${count}/${maxLength}`}
            </p>
        </div>
    )
}

export default CountableTextArea;