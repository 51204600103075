import { useLocation } from 'react-router-dom';

import { Button, Spinner } from 'react-bootstrap';
import BenefitItem from './BenefitItem';
import { URL_POST_CHECKOUT_SESS } from '../../consts/endpoint';

const PlanCard = ({ planItem, planType, currentPlan, minHeight, buttonText, loading, onClick, onSubmit, disabledButton, userId }) => {
    const { id, name, price, monthlyPrice, benefits } = planItem;
    const domainSource = window.location.origin;
    const planIdDiff = Math.abs(parseInt(currentPlan) - parseInt(id))
    // const isCurrentPlan = currentPlan === id || planIdDiff === 2; // What's the point of planIdDiff?
    const isCurrentPlan = currentPlan === id;
    return (
        <div className='d-flex flex-column' style={{ border: '1px solid #D9D9D9', borderRadius: 8, padding: 16, minHeight: minHeight, margin: '10px 0' }}>
            <p style={{ color: '#1E1E1E', fontWeight: 'bold', fontSize: 22 }}>{name}</p>
            <div className='d-flex align-items-end'>
                {planType == 1 && price != 0 && (<p style={{ fontSize: 32, color: '#1E1E1E', marginBottom: 8 }}><s>${monthlyPrice * 12}</s>&nbsp;</p>)}
                <p style={{ fontSize: 32, color: '#1E1E1E', marginBottom: 8 }}>${price}</p>
                <p style={{ color: '#1E1E1E', marginLeft: 8 }}>/ {planType ? 'year' : 'month'}</p>
            </div>
            <div className='flex-fill mb-3'>
                {benefits.map((text, key) => {
                    return (<BenefitItem key={key} text={text} />)
                })}
            </div>
            {
                name === 'Pulse Lite' ?
                <Button onClick={onClick} variant={isCurrentPlan ? 'outline-primary' : 'primary'} style={{ width: '100%' }} disabled={disabledButton && isCurrentPlan}>{buttonText ? buttonText : isCurrentPlan ? 'Your current plan' : `${currentPlan < id ? 'Upgrade' : 'Downgrade'} to ${name}`}</Button>
                :
                <form action={URL_POST_CHECKOUT_SESS} onSubmit={onSubmit} method="POST">
                    {/* Add a hidden field with the lookup_key of your Price */}
                    <input type="hidden" name="lookup_key" value={ id } />
                    <input type="hidden" name="user_id" value={ userId } />
                    <input type="hidden" name="domain" value={ `${domainSource}/checkout` }/>
                    <Button type="submit" variant={isCurrentPlan ? 'outline-primary' : 'primary'} style={{ width: '100%' }} disabled={disabledButton || isCurrentPlan}>
                        {
                            disabledButton ?
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                :                                
                                buttonText ? buttonText : isCurrentPlan ? 'Your current plan' : `${currentPlan < id ? 'Upgrade' : 'Downgrade'} to ${name}`
                        }
                    </Button>
                </form>
            }
        </div>
    )
}

export default PlanCard;