import React from "react";
import { ReactComponent as DeleteIcon } from '../../images/ic_delete_red.svg';
import { Link } from "react-router-dom/dist";

const UploadedFile = ({ title, onClick, ...props }) => {
    return (
        <div {...props}>
            <div className="d-flex w-100"
                style={{
                    border: '1px solid #00802C',
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    borderRadius: 8
                }}>
                <p className="py-0 my-0 flex-fill" style={{ fontSize: '1.25rem', color: '#0F0F0F', textAlign: 'start' }}>{title}</p>
                <Link onClick={() => {
                    if(onClick) onClick(title);
                }}><DeleteIcon /></Link>
            </div>
        </div>
    );
}

export default UploadedFile;