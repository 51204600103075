import { useSelector } from "react-redux";
import { inputSelectors } from "../../store/selectors/InputSelector";

import { ReactComponent as EditIcon } from '../../images/ic_edit.svg';

const MainPageSidebar = ({ step, editQuestions }) => {

  const inputData = useSelector(inputSelectors.data);
  console.log('inputData', inputData);

  return (
    <div className="sidebar-fixed">
      {/* <div className="fullwidth-card mainpage-sidebar" style={{ margin: '64px 0 32px', width: 400, textAlign: 'left', color: '#00171F' }}>
        <div className="attr-container d-flex align-items-center">
          <div className="sidebar-attr">
            <div className="sidebar-title">
              <strong>{ inputData?.name }</strong>
            </div>
            <div className="sidebar-subtitle">{ inputData?.industry }</div>
          </div>
          <div className="sidebar-edit">
            <button onClick={editCompany} className="sidebar-edit-button"><EditIcon />Edit</button>
          </div>
        </div>
        <p className="sidebar-paragraph" style={{ marginBottom: 0 }}>{ inputData?.description }</p>
      </div> */}
      {
        step === 3 &&
        <div className="fullwidth-card mainpage-sidebar sidebar-card">
          <div className="attr-container d-flex align-items-center">
            <div className="sidebar-attr">
              <div className="sidebar-title">
                <strong>Your Questions</strong>
              </div>
            </div>
            <div className="sidebar-edit">
              <button onClick={editQuestions} className="sidebar-edit-button"><EditIcon />Edit</button>
            </div>
          </div>
          {
            inputData?.questions?.length ?
            <div className="question-list-sidebar">
              {inputData?.questions.map( (item, i) => {
                return (
                  <div className="sidebar-list-twolines">
                    <div className="index-list">{i+1}</div>
                    <div key={i} className="content-list">{ item.value }</div>
                  </div>
                )
              } )}
            </div>
            :
            ''
          }
        </div>
      }
    </div>
  )
}

export default MainPageSidebar;