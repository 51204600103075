import React, { useState, useEffect } from "react";
import Stepper from "./commons/Stepper";
import CompanyDesc from "./CompanyDesc";
import SelectQuestions from "./SelectQuestions";
import MainPageSidebar from "./commons/MainPageSidebar";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from '../images/ic_back_24.svg'
import UploadPage from "./UploadPage";
import { v4 as uuidv4 } from 'uuid';
import inMemoryJwtService from "../services/inMemoryJwtService";
import { useSelector } from 'react-redux';
import { userPropsSelectors } from '../store/selectors/UserPropsSelector';

import { Container, Row, Col } from "react-bootstrap";

const MainPage = () => {
    const [step, setStep] = useState(1);
    const userPropsData = useSelector(userPropsSelectors.data);

    useEffect(() => {
        let sessionId = localStorage.getItem('session_id');

        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem('session_id', sessionId);
        }
    }, []);

    const handleNext = () => {
        if(step < 3) setStep(step+1);
    }
    const handleBack = () => {
        if(step > 1) setStep(step-1); 
        else window.history.go(-1);
    }
    const RenderedPage = () => {
        switch(step){
            case 1: return <CompanyDesc onNext={handleNext}/>;
            case 2: return <SelectQuestions onNext={handleNext} userPropsData={ userPropsData } />;
            case 3: return <UploadPage userPropsData={ userPropsData } />;
            default: return <CompanyDesc  onNext={handleNext}/>;
        }
    }

    const StepperNav = () => {
        return (
            <div className="d-flex container-sm align-items-center justify-content-center report-nav-container" style={{ position: 'relative', marginTop: 64, marginBottom: 72 }}>
                <Link className="d-flex align-items-center report-nav-link"
                onClick={handleBack}>
                    <BackIcon />
                    <p className="my-0 ms-2" style={{ color: '#555960', fontSize: '1.5rem', fontWeight: 500 }}>Back</p>
                </Link>
                {
                    userPropsData?.isFirst && <Stepper select={step} style={{ width: '50%' }} onSelected={(num) => setStep(num)}/>
                }
            </div>
        )
    }

    return userPropsData?.isFirst ?
        (
            <div className="d-flex flex-column align-items-center pt-4">
                <StepperNav />
                <RenderedPage />
            </div>
        )
        :
        (
            <div className="d-flex flex-column align-items-center pt-4">
                <Container>
                    {
                        step === 3 ?
                        <div>
                            <StepperNav />
                            <Row className="mainpage-container" style={{ position: 'relative' }} >
                                <Col lg={8} md={12}>
                                    <RenderedPage />
                                </Col>
                                <Col lg={4} md={12}>
                                    <MainPageSidebar step={step} editQuestions={ () => setStep(2) } />
                                </Col>
                            </Row>
                        </div>
                        :
                        <div>
                            <StepperNav />
                            <RenderedPage />
                        </div>                       
                    }
                </Container>
            </div>
        )
}

export default MainPage;