import React, { useState, useEffect } from "react";
import CheckButton from "./CheckButton";
import { Link } from "react-router-dom";

const ItemQuestion = ({ id, question, onClick, ...props }) => {
    const [checked, setCheck] = useState(false);
    useEffect(() => {
        setCheck(props.checked);
    }, [props.checked]);
    return (
        <div {...props}>
            <Link style={{ textDecoration: 'none' }} onClick={e => {
                if (!props.disabled || checked === true) {
                    if (onClick) onClick(!checked);
                    setCheck(!checked);
                }
            }}>
                <div className="d-flex flex-fill align-items-center" style={{ borderRadius: 8, backgroundColor: checked ? '#924DF6' : '#E8E8E8', padding: 12 }}>
                    <p className="my-0 flex-fill" style={{ color: checked ? 'white' : 'black', textAlign: 'start', fontSize: '1.25rem', marginRight: 8 }}>{question}</p>
                    <CheckButton checked={checked} />
                </div>
            </Link>
        </div>
    )
}

export default ItemQuestion;