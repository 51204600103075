import { ReactComponent as IconCheck } from '../../images/ic_check_list.svg';

const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const SpecialCaseSubstring = ({ text, substring, textColor }) => {
    if (!substring || !substring.strong) return <p style={{ fontSize: 16, color: textColor||'#676767', marginLeft: 10, marginTop: 0, marginBottom: 0, textAlign: 'left' }}>{text}</p>;

    const escapedSubstring = escapeRegExp(substring.strong);
    const escapedItalicString = escapeRegExp(substring.italic);
    const parts = text.split(new RegExp(`(${escapedSubstring}|${escapedItalicString})`, 'gi'));
    return (
        <p style={{ fontSize: 16, color: textColor||'#676767', textAlign: 'left', marginLeft: 10, marginTop: 0, marginBottom: 0 }}>
            {parts.map((part, index) =>
                part.toLowerCase() === substring.strong.toLowerCase() ? (
                    <strong key={index}>{part}</strong>
                ) :
                    part.toLowerCase() === substring.italic.toLowerCase() ? (
                        <i key={index}>{part}</i>
                    )
                    :
                    (
                        part
                    )
            )}
        </p>
    );
};

const BenefitItem = ({ text, textColor }) => {
    return (
        <div className="d-flex mt-1">
            <div><IconCheck /></div>
            <SpecialCaseSubstring text={text.value} substring={{strong: text.strong, italic: '(Coming Soon)'}} textColor={textColor}/>
        </div>
    )
}

export default BenefitItem;