import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import CountableTextArea from "./commons/CountableTextArea";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../store/actions/InputAction";
import { inputSelectors } from "../store/selectors/InputSelector";
import axios from "axios";
import inMemoryJwtService from "../services/inMemoryJwtService";
import { URL_POST_GET_USER } from "../consts/endpoint";

const CompanyDesc = ({ onNext, ...props }) => {
    const dispatch = useDispatch();
    const inputData = useSelector(inputSelectors.data);
    const industries = [
        "Accountancy",
        "Advertising",
        "Aerospace",
        "Agriculture",
        "Air Transport",
        "Architecture",
        "Arts, Entertainment and Recreation",
        "Beauty Services",
        "Biopharmaceuticals Manufacturing",
        "Built Environment",
        "Consultancy",
        "Design",
        "Early Childhood Care and Education",
        "Electronics",
        "Energy and Chemicals",
        "Energy and Power",
        "Environmental Services",
        "Finance",
        "Food Manufacturing",
        "Food Services",
        "Healthcare",
        "Hotel and Accommodation Services",
        "Human Resource",
        "Information and Communications Technology",
        "Insurance",
        "Landscape",
        "Legal",
        "Logistics",
        "Marine and Offshore Engineering",
        "Media",
        "Precision Engineering",
        "Public Service (Education)",
        "Public Transport",
        "Real Estate",
        "Retail",
        "Sea Transport",
        "Security",
        "Social Service",
        "Tourism",
        "Training and Adult Education",
        "Wholesale Trade",
        "Others"
    ]
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [description, setDescription] = useState('');

    const fetchUser = async (token) => {
        try {
            const response = await axios.get(URL_POST_GET_USER,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            let company = response.data.company;
            setName(company.name);
            setIndustry(company.industry);
            setDescription(company.description);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (inputData) {
            setName(inputData.name);
            setIndustry(inputData.industry);
            setDescription(inputData.description);
        } else {
            fetchUser(inMemoryJwtService.getToken());
        }
    }, []);

    const checkValidation = () => name.length > 0 && industry.length > 0 && description.length > 0;

    return (
        <div className="d-flex" {...props}>
            <div className="d-flex flex-column container-sm pb-5 px-5 align-items-start">
                <p className="align-self-center" style={{ fontSize: '3rem', fontWeight: 'bold' }}>Tell us about your business</p>
                <p style={{ fontSize: '1.5rem', textAlign: 'left', marginBottom: 34 }}>
                    Describe your business as accurately as possible, so that our model can
                    understand better. The more detailed and specific your description, the
                    better we can tailor insights to your unique needs.
                </p>
                <div className="container px-0">
                    <div className="row">
                        <div className="col">
                            <Form.Control style={{ height: 56 }} placeholder="Company Name" value={name} maxLength="50" onChange={e => setName(e.target.value)} />
                        </div>
                        <div className="col">
                            <Form.Select style={{ height: 56, marginBottom: 24 }} onChange={e => setIndustry(industries[e.target.value])} title="Industry" value={industries.indexOf(industry)}>
                                <option hidden>Industry</option>
                                {industries.map((ind, key) => {
                                    return (
                                        <option key={key} value={key}>{ind}</option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                        <div className="flex-fill"><CountableTextArea placeholder="What does your company do?" maxLength={2500} value={description} onChange={e => setDescription(e.target.value)} /></div>
                    </div>
                </div>
                <Button disabled={!checkValidation()} style={{ fontWeight: 500, fontSize: '1.3rem', height: 50, paddingLeft: 24, paddingRight: 24, marginTop: 40, alignSelf: 'center' }} onClick={() => {
                    dispatch(setCompanyData({ ...inputData, name, industry, description }));
                    onNext();
                }}>Continue</Button>
            </div>
        </div>
    )
}
export default CompanyDesc;