import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as UploadIcon } from '../images/ic_upload.svg'
import QuestionHeader from "./commons/QuestionHeader";
import { Link, useNavigate } from "react-router-dom";
import UploadedFile from "./commons/UploadedFile";
import { useDispatch, useSelector } from "react-redux";
import { inputSelectors } from "../store/selectors/InputSelector";
import { setCompanyData } from "../store/actions/InputAction";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { URL_UPLOAD_DOC } from "../consts/endpoint";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    width: 64px;
    height: 64px;
    background-image: url(ellips.png);
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${spin} 0.6s linear infinite;
`;

const recommendedFiles = [
    {category: 'finance', list: ['Financial Reports', 'Operating Expenses']},
    {category: 'bd', list: ['Marketing Reports', 'Other Business Development Reports']},
    {category: 'ops', list: ['Operating Reports', 'Other Operation Reports']},
    {category: 'hr', list: ['HR Reports', 'Other HR Reports']}
]

const UploadPage = (props) => {
    const inputData = useSelector(inputSelectors.data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadErr, setUploadErr] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const fileInputRef = useRef();
    const [filesRecommendation, setFilesRecommendation] = useState([]);
    const handleFileChange = e => {
        setUploadErr('');
        if (e.target.files[0]) {
            const fileSize = Math.ceil(e.target.files[0].size / 1024)
            const fileName = e.target.files[0].name;
            if (fileSize > 5120) setUploadErr('File larger than 5 MB');
            else if (files.filter(file => file.fileName === fileName).length > 0) setUploadErr('File already uploaded');
            else handleFileUpload(e.target.files[0]);
        }
    }

    useEffect(() => {
        dispatch(setCompanyData({ ...inputData, files }));
        // Using some() to ensure unique objects
        let outputCat = [];
        inputData.questions.forEach(obj => {
            if (!outputCat.some(item => item.category === obj.category)) {
                outputCat.push(obj.category);
            }
        });

        const recFiles = recommendedFiles.filter( rec => outputCat.indexOf(rec.category) > -1 ).map( files => files.list ).flat(Infinity);
        setFilesRecommendation(recFiles);
    }, [files])

    useEffect(() => {
        if (inputData.files) setFiles(inputData.files);
    }, [])

    const canUploadMore = () => {
        if (files.length >= 8) return false;
        return true;
    }

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("pageCount", totalPage);
        try {
            setLoading(true);
            const fileResponse = await axios.post(URL_UPLOAD_DOC, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setTotalPage(totalPage + fileResponse.data.pageCount);
            setFiles([...files, {
                fileName: file.name,
                url: fileResponse.data.url,
            }]);
        } catch (err) {
            const { pageCount, type } = err.response.data
            if (type === "error_total_pages") {
                setUploadErr(`
                    Oops, you have reached the limit for the number document pages you can upload.
                    Max allowed: 100 pages
                    Uploaded: ${totalPage} pages
                    This document has: ${pageCount} pages
                    `)
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="d-flex" {...props}>
            <div className="d-flex flex-column container-sm pb-5 px-5 align-items-start">
                <p className="align-self-center" style={{ fontSize: '3rem', fontWeight: 'bold' }}>Provide us with existing data about your business</p>
                <p style={{ fontSize: '1.5rem', textAlign: 'left', marginBottom: 34 }}>Upload documents that provide insights into the areas of your business that you aim to improve.</p>
                <div className="doc-guide-box">
                    <p>To receive the most accurate business recommendations for the questions you’ve selected, we suggest that you provide the following documents:</p>
                    { filesRecommendation.length &&
                        (
                            <ul>
                                { filesRecommendation.map( (file, i) => <li key={i}><strong>{file}</strong></li> ) }
                            </ul>
                        )
                    }
                </div>
                <QuestionHeader title="Upload" />
                <div className="w-100 p-3" style={{
                    backgroundColor: '#7f11e00d',
                    marginTop: 18,
                    marginBottom: 32,
                    borderRadius: 4,
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23924DF6FF' stroke-width='1' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
                }}>
                    <Link className="w-100" style={{ textDecoration: 'none' }} onClick={() => {
                        if (canUploadMore()) fileInputRef.current.click()
                    }}>
                        <div>
                            {loading && (<div className="d-flex w-100 py-4 justify-content-center align-items-center"><Loader /></div>)}
                            {!loading && (<div>
                                <UploadIcon />
                                <p style={{ color: '#924DF6', fontSize: '1.25rem', fontWeight: 'bold' }}>Browse</p>
                            </div>)}
                            <p style={{ padding: 0, color: '#676767', fontSize: '1.25rem', marginTop: 24 }}>Pulse supports PDF, Docx, Xlsx, and CSV<br />Files must be no larger than 5 MB</p>
                            <p style={{ padding: 0, color: '#D50A0A', fontSize: '1rem', marginTop: 8, whiteSpace: 'pre-line' }}>{uploadErr}</p>
                        </div>
                    </Link>
                    <input onChange={handleFileChange} multiple={false} ref={fileInputRef} type="file" accept=".pdf,.docx,.xlsx,.csv" hidden />
                </div>
                {files.length > 0 && (<QuestionHeader title="Uploaded Files" />)}
                <div className="w-100" style={{ marginBottom: 100 }}>
                    {
                        files.map((file, key) => (
                            <UploadedFile className="w-100" key={key} style={{ marginTop: 18 }} title={file.fileName} onClick={title => setFiles(files.filter(t => t.fileName !== title))} />
                        ))
                    }
                </div>
            </div>
            {files.length > 0 && (<div className="d-flex align-items-center justify-content-center px-5" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, minHeight: 82, backgroundColor: '#E7E2EF', zIndex: 999 }}>
                <p className="my-0 hide-phone-layout" style={{ fontSize: '1.5rem', textAlign: 'left' }}>We’ve go everything we need to help improve your business!</p>
                <Button style={{ fontWeight: 500, fontSize: '1.5rem', marginLeft: 16, marginTop: 16, marginBottom: 16 }} onClick={() => navigate('/result', { replace: true })}>Generate my report</Button>
            </div>)}
        </div>
    )
}
export default UploadPage;