import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button'; 

import { ReactComponent as CheckIcon} from '../images/ic_check_list.svg';
import inMemoryJwtService from '../services/inMemoryJwtService';
import { URL_POST_GET_USER, URL_POST_PORTAL_SESS } from '../consts/endpoint';

const SuccessDisplay = ({ sessionId, user }) => {
  console.log(user);
  const linkTo = user?.plan.token === user?.qToken ? 'core' : 'dashboard';
  const toBilling = '/billing';
  const button = user?.plan.token === user?.qToken ? 'Ask Questions' : 'Go to my dashboard';
  return (
    <div className="d-flex flex-column align-items-center">
      <div style={{ marginTop: 140 }}>
          {/* <PantherLogo style={{ width: 96, height: 96, marginTop: '-110%', padding: 0 }} /> */}
          <CheckIcon style={{ width: 96, height: 96, marginTop: '-10%', padding: '1em', border: '3px solid #00802c', borderRadius: '50%' }} />
      </div>
      <p className="py-0" style={{ fontSize: '3rem', fontWeight: 'bold', marginTop: 24 }}>Payment Succeeded</p>
      <>
          {/* <p style={{ fontSize: '1.5rem' }}>We will send a copy of your report to <b>{email}</b>.</p> */}
          <p style={{ fontSize: '1.5rem' }}>Thank you for your payment.</p>
          {/* <Button style={{ fontSize: '1.5rem', fontWeight: 500 }} href={report} target="_blank" rel="noopener noreferrer"><DownloadIcon className="me-2" />Download my report</Button> */}
          <div className="d-flex align-items-center" style={{ marginTop: '2em' }}>
            {/* <form action={URL_POST_PORTAL_SESS} method="POST">
              <input
                type="hidden"
                id="session-id"
                name="session_id"
                value={ sessionId }
                /> */}
            <Button style={{ background: 'transparent', border: 'none', color: '#924DF6', fontSize: '1.5rem' }} href={ toBilling } id="checkout-and-portal-button">Billing Page</Button>
            {/* </form> */}
            <Button style={{ fontSize: '1.5rem', fontWeight: 500 }} href={linkTo}>{button}</Button>
          </div>
      </>
    </div>
  )
};

const Message = ({ message }) => (
  <div className="d-flex flex-column align-items-center" style={{ textAlign: 'center', marginTop: 140 }}>
    <section>
      <p>{message}</p>
      <Button style={{ fontSize: '1.5rem', fontWeight: 500 }} href={'dashboard'}>Back to dashboard</Button>
    </section>
  </div>
);


const CheckoutPage = (props) => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  const [user, setUser] = useState();

  const fetchUser = async (token) => {
    try {
        const response = await axios.get(URL_POST_GET_USER,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        setUser(response.data);
    } catch (err) {
        console.error(err);
    }
};

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    fetchUser(inMemoryJwtService.getToken());
  }, []);

  if (!success && message === '') {
    return (
      <div>This page is not found</div>
    )
  } else if (success && sessionId !== '') {
    return (
      <SuccessDisplay sessionId={sessionId} user={user}/>
    );
  } else {
    return (
      <div>
        <Message message={message} />
      </div>
    );
  }
};

export default CheckoutPage;