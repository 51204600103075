import React from "react";
import ItemQuestion from "./ItemQuestion";
import QuestionHeader from "./QuestionHeader";

const QuestionGroup = ({group, questions, disabled, selectedQuestions, onCheckChange}) => {
    return (
        <>
            <QuestionHeader title={group} style={{marginTop: 32, marginBottom: 18 }} />
            {
                questions.map((q, i) => (<ItemQuestion key={i} disabled={disabled} style={{ marginBottom: 16 }} question={q.value} onClick={(checked) => onCheckChange(q, checked)} checked={selectedQuestions.filter(ques => ques.id === q.id).length === 1} />))
            }
        </>
    )
}

export default QuestionGroup;