import React from "react";
import { Link } from "react-router-dom";

const ItemStepper = (props) => {
    const { num, selected, onClick } = props
    return (
        <div {...props} style={{textDecoration: 'none'}} onClick={e => {
            if (onClick) onClick(num);
        }}>
            <div className="d-flex align-items-center justify-content-center"
                style={{
                    backgroundColor: selected ? '#924DF6' : '#D9D9D9',
                    borderRadius: 48,
                    width: 48,
                    height: 48
                }}>
                <p className="m-0" style={{ color: selected ? 'white' : 'black', fontWeight: 500 }}>{num}</p>
            </div>
        </div>
    );
}

export default ItemStepper;