import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as Logo } from '../../images/pp-header.svg'
import { Link, useNavigate, useLocation } from "react-router-dom";
import inMemoryJwtService from "../../services/inMemoryJwtService";
import ClickAwayListener from "react-click-away-listener";
import { auth } from "../../utils/firbase.utils";
import { setCompanyData } from "../../store/actions/InputAction";
import axios from "axios";
import { ReactComponent as MenuIcon } from '../../images/ic_menu.svg';

import DrawerNavigation from './DrawerNavigation';
import { Placeholder } from "react-bootstrap";
import { URL_POST_GET_USER } from "../../consts/endpoint";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = inMemoryJwtService.getToken();
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState();
    const [menuActive, setMenuActive] = useState(false);

    const location = useLocation();
    const { pathname } = location;

    const arrDrawerPageList = ['/dashboard', '/reports', '/settings', '/billing'];

    const fetchUser = async (token) => {
        try {
            const response = await axios.get(URL_POST_GET_USER,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setUser(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchUser(token);
    }, []);

    return (
        <div>
            <div className="d-flex align-items-center shadow-sm bg-white" style={{ position: 'fixed', left: 0, right: 0, height: 64, backgroundColor: 'white', zIndex: 10000, justifyContent: 'space-between' }}>
                <div className={`ps-3 align-items-center${menuActive ? ' active' : ''}`}>
                    {
                        arrDrawerPageList.indexOf(pathname.toString()) > -1 &&
                        <div className={`menuicon-btn${menuActive ? ' active' : ''}`} onClick={() => setMenuActive(!menuActive)}>
                            <MenuIcon />
                        </div>
                    }
                    <Link to='/dashboard'><Logo /></Link>
                </div>
                {/* <div className="flex-grow-1" /> */}
                <ClickAwayListener onClickAway={e => setOpen(false)}>
                    <div>
                        <Link className="d-flex align-items-center" style={{ textDecoration: 'none', color: '#191C1B' }} onClick={() => setOpen(true)}>

                            {
                                !user ? (
                                    <Placeholder animation="glow" as="p" style={{ width: 100, height: 12 }}>
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                ) : <div style={{ color: '#676767' }}>{user.plan.name}</div>
                            }

                            <div className="mx-2" style={{ width: 4, height: 4, borderRadius: 4, backgroundColor: '#676767' }} />
                            {
                                !user ? (
                                    <Placeholder animation="glow" as="p" style={{ width: 100, height: 12 }}>
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                ) : <div className="user-header-placeholder">{user?.name}</div>
                            }
                            <div className="d-flex align-items-center justify-content-center mx-3" style={{ height: 48, width: 48 }}>
                                <img src={user?.picture} className="w-100 h-100" style={{ borderRadius: 32 }} />
                            </div>
                        </Link>
                        {open && (<Link className="position-absolute shadow" style={{
                            padding: '16px 32px',
                            top: 72,
                            right: 32,
                            borderRadius: 4,
                            textDecoration: 'none',
                            backgroundColor: 'white',
                            color: '#191C1B'
                        }} onClick={e => auth.signOut().then(() => {
                            localStorage.removeItem('session_id');
                            dispatch(setCompanyData(null));
                            navigate('/');
                        }).catch(err => {
                            console.error(err);
                        })}>
                            <div>
                                Logout
                            </div>
                        </Link>)}
                    </div>
                </ClickAwayListener>
            </div>
            {
                arrDrawerPageList.indexOf(pathname.toString()) > -1 &&
                <DrawerNavigation menuActive={ menuActive } hideMenuActive={ () => setMenuActive(false) } />
            }
        </div>
    )
}

export default Header;
