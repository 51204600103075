import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

import PpCardBg from '../../images/pp-card-bg.svg';
import PpPatternBg1 from '../../images/pp-pattern-bg-1.svg';

import { ReactComponent as ChecklistIcon } from '../../images/ic_check_list.svg';
import { URL_POST_CHECKOUT_SESS } from '../../consts/endpoint';

const SubscriptionOfferBanner = ({ userPropsData, returnLink }) => {
  const [disabledButton, setDisabledButton] = useState(false);

  const id = userPropsData?.plan?.id === '3' ? '5' : '4';
  const userId = userPropsData?.id;
  
  console.log('userPropsData', userPropsData);
  
  return (
    <div className="section-container banner-section">
      <div className="fullwidth-card" style={{ backgroundImage: `url(${PpPatternBg1})`, backgroundRepeat: 'no-repeat', backgroundSize: '120%' }}>
        <div className="text-section">
          <div className="intro-label-card">Need more?</div>
          <div className="pseudo-header-card">Unlock <span className="pp-main-color">Pulse Premium</span> Today</div>
          <div className="pseudo-list-wrapper">
            <div className="pseudo-list-card">
              <ChecklistIcon className="pseudo-list-icon" />
              <div><span className="linethrough-text">30</span> <b>50 questions</b>/month</div>
            </div>
            <div className="pseudo-list-card">
              <ChecklistIcon className="pseudo-list-icon" />
              <div>Your personal <b>Qualitative Insights Dashboard</b></div>
            </div>
            <div className="pseudo-list-card">
              <ChecklistIcon className="pseudo-list-icon" />
              <div>Detailed <b>reports</b></div>
            </div>
            <div className="pseudo-list-card">
              <ChecklistIcon className="pseudo-list-icon" />
              <div>More features for growing your business</div>
            </div>
          </div>
          {/* <Link to='/' className="pseudo-button-card">Upgrade for $1.64/day</Link> */}
          <form action={URL_POST_CHECKOUT_SESS} onSubmit={e => setDisabledButton(true)} method="POST">
            {/* Add a hidden field with the lookup_key of your Price */}
            <input type="hidden" name="lookup_key" value={ id } />
            <input type="hidden" name="user_id" value={ userId } />
            <input type="hidden" name="domain" value={ returnLink }/>
            <Button type="submit" className="pseudo-button-card" disabled={disabledButton}>
              {
                disabledButton ?
                  <Spinner animation="border" role="status" size='sm'>
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  :                                
                  'Upgrade for $1.64/day'
              }
            </Button>
          </form>
        </div>
        <div className="blank-bg-section" style={{ backgroundImage: `url(${PpCardBg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', display: 'block', position: 'absolute', top: 0, right: '0', width: '380px', height: '380px' }}></div>
      </div>
    </div>
  );
};

export default SubscriptionOfferBanner;