import { Link } from "react-router-dom";

const ButtonTab = ({ text, selected, onItemClick }) => {
    return (
        <Link style={{
            padding: 8,
            textDecoration: 'none',
            marginRight: 8,
            backgroundColor: selected ? '#924DF6' : 'white',
            borderRadius: 12
        }} onClick={onItemClick}>
            <p style={{
                margin: 0,
                fontSize: 14,
                color: selected ? 'white' : '#1E1E1E',
                fontWeight: 600
            }}>{text}</p>
        </Link>
    )
}

export default ButtonTab;