import React from "react";

const QuestionHeader = (props) => {
    return (
        <div className="d-flex w-100 align-items-center" {...props}>
            <p className="my-0 me-3" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#676767' }}>{props.title}</p>
            <div className="flex-fill" style={{ height: 1, backgroundColor: '#E8E8E8' }} />
        </div>
    )
}

export default QuestionHeader;