import React, { useState } from "react";
import axios from "axios";
import { ReactComponent as PantherLogo } from '../images/original_logo.svg';
import { ReactComponent as GoogleLogo } from '../images/ic_google.svg';
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { signInWithGooglePopup } from "../utils/firbase.utils";
import inMemoryJwtService from "../services/inMemoryJwtService";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/actions/AuthAction";
import { URL_POST_GET_USER } from "../consts/endpoint";

const LandingPage = () => {
    const dispatch = useDispatch();
    const [loginErr, setLoginErr] = useState(null);
    const handleSignUp = async () => {
        try {
            const response = await signInWithGooglePopup();
            postUser(response.user.accessToken);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSignIn = async () => {
        try {
            const response = await signInWithGooglePopup();
            fetchUser(response.user.accessToken);
        } catch (err) {
            console.error(err);
        }
    };

    const postUser = async (token) => {
        try {
            await axios.post(URL_POST_GET_USER,
                {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            );
            inMemoryJwtService.setToken(token);
            dispatch(setUserData(token));
        } catch (err) {
            setLoginErr(err.response.data.message);
        }
    }

    const fetchUser = async (token) => {
        try {
            await axios.get(URL_POST_GET_USER,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            inMemoryJwtService.setToken(token);
            dispatch(setUserData(token));
        } catch (err) {
            console.error(err);
            setLoginErr(err.response.data.message);
        }
    }
    return (
        <div className="d-flex vw-100 align-items-center justify-content-center"
            style={{
                backgroundColor: '#00171F',
                padding: 24,
                minHeight: '100vh'
            }}>
            <Row>
                <Col lg={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <PantherLogo style={{ marginLeft: 40, marginRight: 40, marginBottom: 16, maxWidth: '100%' }} />
                </Col>
                <Col lg={6}>
                    <div className="d-flex flex-fill flex-column bg-white" style={{ marginLeft: 40, marginRight: 40, marginTop: 16, padding: 32, borderRadius: 8 }}>
                        <p className="py-0" style={{ fontSize: '2rem', color: '#191C1B' }}>Create your account to get started with Pantherpulse</p>
                        <Button variant="light" style={{ display: 'flex', fontWeight: 400, marginTop: 32, marginBottom: 32 }} onClick={handleSignUp}>
                            <GoogleLogo />
                            <p className="my-0 flex-fill">Sign up with Google</p>
                        </Button>
                        <p style={{ textAlign: 'left', margin: 0 }}>
                            Already have an account?
                            <Link style={{
                                marginLeft: 4,
                                textDecoration: 'none',
                                color: '#924DF6'
                            }} onClick={handleSignIn}>Log In</Link>
                        </p>
                        {loginErr && (<p style={{ textAlign: 'left', padding: 0, margin: 0, color: '#D50A0A', fontSize: '1rem', whiteSpace: 'pre-line' }}>{loginErr}</p>)}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LandingPage;