import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Col, Row, Placeholder } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import inMemoryJwtService from "../services/inMemoryJwtService";
import PlanCard from "./commons/PlanCard";
import PlanCardLoading from "./commons/PlanCardLoading";
import { URL_GET_PRICING, URL_POST_GET_USER } from "../consts/endpoint";
import TabLayout from './commons/TabLayout';

const Welcome = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [planType, setPlanType] = useState(1)

    const fetchPlans = async () => {
        try {
            const response = await axios.get(URL_GET_PRICING);
            setPlans(response.data.sort((a, b) => a.id.localeCompare(b.id)));
            setDisabledButton(false);
        } catch (err) {
            console.log('err', err);
        }
    }
    
    const fetchUser = async (token) => {
        try {
            const response = await axios.get(URL_POST_GET_USER,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            if(response.data.plan.id > 1) navigate('/core', {replace: true});
            console.log('runn');
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchUser(inMemoryJwtService.getToken());
        fetchPlans();
    }, []);

    let decodedToken = {};
    if (inMemoryJwtService.getToken()) {
        decodedToken = jwtDecode(inMemoryJwtService.getToken());
    }

    console.log('plans', plans);

    return (
        <div className="d-flex align-items-start" style={{ minHeight: '100vh' }}>
            <div className="d-flex flex-column container-sm px-5 pb-5 align-items-start" style={{ marginTop: 80 }}>
                <p style={{ fontSize: '2rem' }}>Hi {decodedToken.name.split(' ')[0]}, welcome to Pulse AI</p>
                <p style={{ textAlign: 'left', marginBottom: 40 }}>
                    Our application empowers SMB owners and key decision-makers to extract valuable insights from
                    their business data. By following the 3 simple steps below, our powerful algorithms will analyse
                    your data and generate a comprehensive report, delivered straight to your inbox.
                    <br /><br />The more detailed and specific your data and questions, the more accurate and actionable
                    the insights will be. Unlock the full potential of your company data and make informed decisions
                    to drive your business forward.
                </p>
                <Container>
                    <Row style={{ alignItems: 'end' }}>
                    <TabLayout initialSelected={planType} onTabSelected={selected => setPlanType(selected)} items={["Monthly", "Yearly"]} style={{ marginBottom: 24 }} />
                        {
                            plans.length === 0 ? (
                                <>
                                    <Col lg={4} md={12}><PlanCardLoading /></Col>
                                    <Col lg={4} md={12}><PlanCardLoading /></Col>
                                    <Col lg={4} md={12}><PlanCardLoading /></Col>
                                </>
                            ) : plans.filter(plan => plan.type === planType || plan.id === '1').map((plan, key) => {
                                return (
                                    <Col key={key} lg={4} md={12}>
                                        {
                                            plan.name === 'Pulse Lite' ?
                                            <PlanCard planType={planType} planItem={plan} currentPlan="1" minHeight={0} buttonText={`Get started with ${plan.name}`} onClick={e => navigate('/core')} />
                                            :
                                            <PlanCard planType={planType} planItem={plan} currentPlan="1" minHeight={0} buttonText={`Get started with ${plan.name}`} onSubmit={e => setDisabledButton(true) } disabledButton={disabledButton} userId={ decodedToken?.user_id } />
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default Welcome;