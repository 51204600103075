import React, { useState, useEffect } from "react";
import CountableTextArea from './CountableTextArea';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const InputQuestion = ({onSave, onCancel, value}) => {
    const [question, setQuestion] = useState(value || '');
    return (
        <div className="d-flex flex-column flex">
            <div className="flex-fill">
                <CountableTextArea value={question} onChange={e => setQuestion(e.target.value)} placeholder="Write your question here..." height={142} />
            </div>
            <div className="d-flex flex-row-reverse mt-2 align-items-center">
                <Button
                    disabled={question === ''}
                    style={{ fontSize: '1.5rem', fontWeight: 500 }} onClick={e => {
                        setQuestion('');
                        onSave(question);
                    }}>Save</Button>
                <Link className="me-3" style={{ color: '#924DF6', fontSize: '1.5rem', textDecoration: 'none' }} onClick={e => {
                    setQuestion('');
                    onCancel();
                }}><p className="my-0">Cancel</p></Link>
            </div>
        </div>
    )
};

export default InputQuestion;