import { Link } from "react-router-dom";
import Checkbox from "./Checkbox";
import { useEffect, useState } from "react";
const ItemCheckbox = ({ title, onCheckedChange, checked }) => {
    const [stateChecked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(checked);
    }, [checked])
    return (
        <Link className="d-flex align-items-center"
            style={{ textDecoration: 'none' }}
            onClick={() => {
                setChecked(!stateChecked);
                if(onCheckedChange) onCheckedChange(!stateChecked);
            }}>
            <p style={{ margin: 0, flexGrow: 1, color: '#1D1B20' }}>{title}</p>
            <Checkbox checked={stateChecked}/>
        </Link>
    )
}

export default ItemCheckbox;