import { GET_USER_PROPS_DATA, SET_USER_PROPS_DATA } from "./ActionTypes";

export function setUserPropsData(data) {
    return {
        type: SET_USER_PROPS_DATA,
        data,
    };
}

export function getUserPropsData(){
    return {
        type: GET_USER_PROPS_DATA,
    };
}