import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from '../../images/ic_delete.svg';
import { ReactComponent as EditIcon } from '../../images/ic_edit.svg';
import InputQuestion from "./InputQuestion";

const CustomQuestion = ({ onEdit, onDelete, onValueChange, question, ...props }) => {
    const [expand, setExpand] = useState(false);
    const [edit, setEdit] = useState(false);
    return (
        <div className="w-100" {...props}>
            {!edit && (<div className="d-flex flex-fill" style={{ borderRadius: 8, backgroundColor: '#924DF6', padding: 12 }}>
                <Link onClick={() => setEdit(true)}><EditIcon /></Link>
                <p className="my-0 flex-grow-1"
                    style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: 'white',
                        textAlign: 'start',
                        fontSize: '1.25rem',
                        marginRight: 8,
                        marginLeft: 8
                    }}
                    onClick={() => {
                        setExpand(!expand);
                    }}>{question}</p>
                <Link onClick={onDelete}><DeleteIcon /></Link>
            </div>)}
            {edit && (
                <div className="w-100 p-3" style={{
                    backgroundColor: '#E7E2EF',
                    marginTop: 18,
                    borderRadius: 4,
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23924DF6FF' stroke-width='1' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
                }}>
                    <InputQuestion value={question} onCancel={() => setEdit(false)} onSave={q => {
                        setEdit(false);
                        onValueChange(q);
                    }} />
                </div>
            )}
        </div>
    )
}

export default CustomQuestion;