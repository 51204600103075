
import { useState } from "react";
import ItemCheckbox from "./commons/ItemCheckbox";
import { Button, Form, Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../images/ic_close.svg";
import inMemoryJwtService from "../services/inMemoryJwtService";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { setUserPropsData, getUserPropsData } from '../store/actions/UserPropsAction';
import { URL_POST_CANCEL_SUBS } from "../consts/endpoint";

const CancelPlanModal = (props) => {
    const dispatch = useDispatch();
    const reasons = [
        'Too expensive',
        'Not useful for me',
        'I found it difficult to use',
        'I no longer need it',
        'Other (please specify)'
    ]
    const [reason, setReason] = useState(null);
    const [otherReason, setOtherReason] = useState('');
    const [ongoingCancellation, setOngoingCancellation] = useState(false);

    const cancelPlanSubmit = async (e) => {
        e.preventDefault();
        setOngoingCancellation(true);
        try{
            console.log('start cancellation');
            const response = await axios.post(URL_POST_CANCEL_SUBS,
                { activity: 'cancel_at_period_end' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${inMemoryJwtService.getToken()}`
                    }
                }
            );
            console.log('response', response);
            const updatedUserProps = response.data?.userProps;
            dispatch(setUserPropsData(updatedUserProps));
            props.onHide();
            // props.onCancelSucceed();
        } catch (err) {
            console.log('error', err);
            alert('Cancel failed');
        }
        setOngoingCancellation(false);
    };

    return (
        <Modal {...props} size="lg" centered>
            <div style={{ padding: 24 }}>
                <div className="d-flex">
                    <p style={{ flexGrow: 1, fontSize: 32, color: '#00171F' }}>Cancel Plan</p>
                    <Button variant="link" style={{height: 48, width: 48}} onClick={props.onHide}><CloseIcon /></Button>
                </div>
                <p style={{ fontSize: 16, color: '#00171F' }}>Thank you for choosing Pulse AI, we’re sorry to see you go. Before you cancel your subscription, please tell us why you decided to cancel.</p>
                <div style={{ backgroundColor: '#D9D9D9', height: 1, marginTop: 16, marginBottom: 16 }} />
                {reasons.map((item, key) => <ItemCheckbox
                    key={key}
                    title={item}
                    checked={key === reason}
                    onCheckedChange={checked => {
                        if (checked) setReason(key);
                        else setReason(null);
                    }} />
                )}
                {reason === 4 && (<Form.Control style={{ height: 56 }} placeholder="Reason" value={otherReason} maxLength="255" onChange={e => setOtherReason(e.target.value)} />)}
                <div style={{ backgroundColor: '#D9D9D9', height: 1, marginTop: 16, marginBottom: 16 }} />
                <div className="d-flex flex-row-reverse">
                    <Button variant="danger" onClick={cancelPlanSubmit} disabled={ongoingCancellation}>Cancel Plan</Button>
                    <Button variant="link" style={{
                        textDecoration: 'none',
                        marginRight: 24,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: '#924DF6',
                        fontWeight: 500
                    }} onClick={props.onHide}>Keep my plan</Button>
                </div>
            </div>
        </Modal>
    )
}

export default CancelPlanModal;